import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

export default function Body() {
  return (
    // <div style={{ width: '100%' }}>

    <Grid
      container
      spacing={2}
      px={{ md: 10, xs: 2 }}
      sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }}
    >
      <Grid item md={6} xs={12} spacing={0} container>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            component="h3"
            sx={{
              fontWeight: 'bold',
              fontFamily: ['Avenir Next'],
              pb: 2,
            }}
          >
            Discover. Try. Buy Local:
          </Typography>
          <Typography
            variant="h4"
            component="h4"
            sx={{
              fontWeight: 'bold',
              fontFamily: ['Avenir Next'],
              pb: 2,
            }}
          >
            Your Neighborhood Product Sharing Marketplace
          </Typography>

          <Typography
            variant="body2"
            style={{
              fontFamily: ['Avenir Next'],
              fontWeight: 500,
              color: 'grey',
              fontSize: 22,
              pl: 2,
            }}
          >
            Find nearby owners of products you want
          </Typography>
          <Typography
            variant="body2"
            style={{
              fontFamily: ['Avenir Next'],
              fontWeight: 500,
              color: 'grey',
              fontSize: 22,
              pl: 2,
            }}
          >
            Test items in person before purchasing
          </Typography>
          <Typography
            variant="body2"
            style={{
              fontFamily: ['Avenir Next'],
              fontWeight: 500,
              color: 'grey',
              fontSize: 22,
              pl: 2,
            }}
          >
            Make confident buying decisions
          </Typography>
          <Typography
            variant="body2"
            style={{
              fontFamily: ['Avenir Next'],
              fontWeight: 500,
              color: 'grey',
              fontSize: 22,
              pl: 2,
            }}
          >
            Earn by sharing your own products
          </Typography>

          <Typography
            variant="h6"
            style={{
              fontFamily: ['Avenir Next'],
              fontWeight: 700,
              // color: 'grey',
              fontSize: 24,
              paddingTop: 40,
            }}
          >
            Shop smart. Connect local. Save time and money.
          </Typography>
        </Grid>
        <Grid item xs={12} container>
          <Grid item sm={5} xs={6}>
            {/* <img
              src="/app-store.svg"
              alt="Logo"
              style={{
                height: { sx: '58px', sm: 80 },
                // width:'100%',
              }}
            /> */}
            <Box
              component="img"
              sx={{
                width: {xs:'90%', sm:'80%'}, // Full width of the container
                height: 'auto',          // Maintain aspect ratio
                // maxWidth: { sx: '58px', sm: 80 },
              }}
              alt="Responsive example"
               src="/app-store.svg"
            />
          </Grid>
          <Grid item xs={6} sm={5}>
            {/* <img
              src="/google-play.png"
              alt="Logo"
              style={{
                height: { sx: '58px', sm: 80 },
                // marginLeft: 20,
                // width:'100%',
              }}
            /> */}
              <Box
              component="img"
              sx={{
                width: {xs:'100%', sm:'90%'}, // Full width of the container
                height: 'auto',          // Maintain aspect ratio
                // maxWidth: { sx: '58px', sm: 80 },
              }}
              alt="Responsive example"
               src="/google-play.png"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6} sx={12}>
        <img
          src="/appimage.jpg"
          alt="Logo"
          style={{
            width: '100%',
            borderRadius: '5%',
          }}
        />
      </Grid>
    </Grid>
  );
}
